<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-15 10:25:11
-->
<template>
  <el-card class="box">
    <el-tabs v-model="activeName">
      <el-tab-pane label="基本参数" name="base">
        <edit-row
          label="管理系统名称"
          :value="settings.siteName"
          ak="SiteName"
          @success="loadData"
        ></edit-row>
        <edit-row
          label="公司名称"
          :value="settings.companyName"
          ak="CompanyName"
          @success="loadData"
        ></edit-row>
        <edit-row
          label="短信签名"
          :value="settings.smsSign"
          ak="smsSign"
          @success="loadData"
        ></edit-row>
      </el-tab-pane>

      <el-tab-pane label="报备参数" name="report">
        <edit-row
          label="报备处理时间"
          :tip="`(报备部上班时间)`"
          :value="settings.workingTime"
          ak="WorkingTime"
          @success="loadData"
        ></edit-row>
        <edit-row
          label="项目选择数量限制"
          :tip="`(单次报备时最多可选择${settings.limitEstates}个项目)`"
          :value="settings.limitEstates"
          ak="LimitEstates"
          @success="loadData"
        ></edit-row>
        <edit-row
          label="客户添加数量限制"
          :tip="`(单次报备时最多可添加${settings.limitClients}个客户)`"
          :value="settings.limitClients"
          ak="LimitClients"
          @success="loadData"
        ></edit-row>
        <edit-row
          label="报备限制"
          :tip="`(同一账号同一客户同一项目一天只能报备${settings.limitReportOneClient24}条)`"
          :value="settings.limitReportOneClient24"
          ak="LimitReportOneClient24"
          @success="loadData"
        ></edit-row>
        <edit-row
          label="客户项目限制"
          :tip="`(一客户一天只能报备${settings.limitClientEstate24}个项目)`"
          :value="settings.limitClientEstate24"
          ak="LimitClientEstate24"
          @success="loadData"
        ></edit-row>

        <edit-row
          label="短信通知专员"
          :tip="`开启分销报备短信通知专员。1：是，0：否`"
          :value="settings.reportNotifyZhuanyuan"
          ak="reportNotifyZhuanyuan"
          @success="loadData"
        ></edit-row>
      </el-tab-pane>

      <el-tab-pane label="邮箱参数" name="email">
        <edit-row
          label="主机Host"
          :value="settings.emailHost"
          ak="EmailHost"
          @success="loadData"
        ></edit-row>
        <edit-row
          label="端口Port"
          :value="settings.emailPort"
          ak="EmailPort"
          @success="loadData"
        ></edit-row>
        <edit-row
          label="账号"
          :value="settings.emailAccount"
          ak="EmailAccount"
          @success="loadData"
        ></edit-row>
        <edit-row
          label="授权码"
          :value="settings.emailPassword"
          ak="EmailPassword"
          @success="loadData"
        ></edit-row>
        <edit-row
          label="开启错误邮件"
          tip="(系统错误发送邮件通知。0:关闭，1：开启)"
          :value="settings.emailErrorPush"
          ak="EmailErrorPush"
          @success="loadData"
        ></edit-row>
        <edit-row
          label="系统错误接收邮箱"
          :value="settings.errorToMailAddress"
          ak="ErrorToMailAddress"
          @success="loadData"
        ></edit-row>
      </el-tab-pane>

      <el-tab-pane label="分享下载" name="share">
        <edit-row
          label="分享二维码"
          :value="settings.shareQRCode"
          ak="shareQRCode"
          @success="loadData"
        ></edit-row>
        <edit-row
          label="APP下载地址"
          :value="settings.appDownUrl"
          ak="appDownUrl"
          @success="loadData"
        ></edit-row>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import EditRow from "./editrow.vue";

export default {
  components: {
    EditRow,
  },
  data() {
    return {
      activeName: "base",
      settings: {},
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.$http.get("admin/v1/setting").then((res) => {
        if (res.code == 0) {
          this.settings = res.data;
        }
      });
    },
  },
};
</script>

<style scoped></style>
