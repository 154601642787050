<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-12-12 16:07:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-12-13 11:13:59
-->
<template>
  <div class="edit-row">
    <label>{{label}}</label>
    <div class="editable">
      <span class="ak" v-if="!visible" @click="onEdit">
        {{
        value
        }}
        <span class="tip">{{ tip }}</span>
      </span>
      <div class="value" v-if="visible">
        <el-input v-model="form.value" class="input" placeholder clearable autofocus size="small"></el-input>
        <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
        <el-button type="primary" link  @click="onCancel" :disabled="saving">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      saving: false,
      form: {},
    };
  },
  props: ["label", "ak", "value","tip"],
  emits: ["success"],
  watch: {
    value: {
      handler() {
        this.form.value = this.value;
      },
      immediate: true,
    },
  },
  methods: {
    /**
     *
     */
    onEdit() {
      this.visible = true;
    },
    /**
     *
     */
    onCancel() {
      this.form.value = this.value;
      this.visible = false;
    },

    /**
     * 提交更改
     */
    onSubmit() {
      this.saving = true;
      this.$http
        .post("admin/v1/setting/save", {
          name: this.ak,
          value: this.form.value,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.$emit("success");
            this.visible = false;
          }
        })
        .finally(() => (this.saving = false));
    },
  },
};
</script>

<style scoped>
.edit-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  font-size: 14px;
}
.edit-row label {
  width: 160px;
  text-align: right;
  color: var(--text-label-color);
}
.edit-row .editable {
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.edit-row span.ak {
  border-bottom: 1px dashed var(--el-border-color);
  cursor: pointer;
  color: var(--el-color-primary);
}
.edit-row span.ak small {
  color: var(--text-label-color);
}

.editable .value {
  height: 100%;
}

.editable .el-input {
  height: 100%;
}

.editable .input {
  margin-right: 10px;
  width: 360px;
}

.editable .tip{
  color: #999;
}
</style>